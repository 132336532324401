import { Button, Icon, Reorder, ReorderGroup } from '@acciona/ui-ionic-kit';
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react';
import _ from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LinkItem, ServiceCustomContentConfig } from '../../../../_api/services/generalServices/types';
import CustomModal from '../../../../components/CustomModal/CustomModal';
import { PopOverMenu } from '../../../../components/PopOverMenu/PopOverMenu';
import { TextInput } from '../../../../components/TextInput/TextInput';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../../utils/constants';
import { checkSelectedTemplate, newLinkItem, templateOptions } from '../helpers';
import { EditCustomContentImgModal } from './EditCustomContentImgModal/EditCustomContentImgModal';
import { EditCustomContentLink } from './EditCustomContentLink/EditCustomContentLink';
import styles from './styles.module.scss';

type Props = {
	hasWritePermission: boolean;
	selectedLang: string;
	customContentData: ServiceCustomContentConfig;
	handleChangeCustomContent: (field: string, value: any) => void;
	setSuccess: Function;
	setError: Function;
	handleReorderItemList: (e: any) => void;
	handleDeleteItem: (id: number) => void;
};
export const CustomContent: React.FC<Props> = ({
	hasWritePermission,
	selectedLang,
	customContentData,
	handleChangeCustomContent,
	setSuccess,
	setError,
	handleReorderItemList,
	handleDeleteItem,
}) => {
	const { t } = useTranslation();
	const [edit, setEdit] = useState(true);
	const selectedTemplate = checkSelectedTemplate(customContentData);
	const [showImgModal, setShowImgModal] = useState(false);
	const [showLinkModal, setShowLinkModal] = useState(false);
	const [selectedLink, setSelectedLink] = useState<LinkItem>(newLinkItem);

	const [deleteId, setDeleteId] = useState(-1);

	const handleGroupReorder = (e: any) => {
		e.detail.complete();
		handleReorderItemList(e);
	};

	return (
		<>
			<CustomModal
				showModal={deleteId !== -1}
				title={t('title_delete_link')}
				description={t('body_delete_link')}
				onConfirm={() => {
					handleDeleteItem(deleteId);
					setDeleteId(-1);
				}}
				onClose={() => {
					setDeleteId(-1);
				}}
			></CustomModal>
			<EditCustomContentLink
				showModal={showLinkModal}
				onClose={() => {
					setShowLinkModal(false);
					setSelectedLink(newLinkItem);
				}}
				hasWritePermission={hasWritePermission}
				linkData={selectedLink}
				selectedLang={selectedLang}
				key={selectedLink?.id}
				handleChangeCustomContent={handleChangeCustomContent}
				setSuccess={setSuccess}
			/>
			<EditCustomContentImgModal
				showModal={showImgModal}
				onClose={() => setShowImgModal(false)}
				hasWritePermission={hasWritePermission}
				fileData={customContentData.images}
				setFileData={(data) => handleChangeCustomContent('images', data)}
				setError={setError}
			/>
			<div className={styles.element}>
				<h3 className={styles.blockTitle}>{t('lbl_select_template_title')}</h3>
				<p className={`${styles.blockSubtitle}`}>{t('lbl_select_template_desc')}</p>
				<IonRadioGroup
					className={styles.templateOptions}
					value={selectedTemplate}
					onIonChange={(e) => {
						handleChangeCustomContent('template', e.target.value);
					}}
				>
					{templateOptions.map((option) => (
						<IonItem key={option.id} className={styles.selector} lines="none">
							<IonRadio
								slot="start"
								mode="md"
								value={option.value}
								aria-label={t(option.configurationName)}
								labelPlacement="end"
							>
								{t(option.configurationName)}
							</IonRadio>
						</IonItem>
					))}
				</IonRadioGroup>
			</div>
			<div className={styles.element}>
				<h3 className={styles.blockTitle}>{t('lbl_img_and_subtitle_title')}</h3>
				<p className={`${styles.blockSubtitle}`}>{t('lbl_img_and_subtitle_desc')}</p>
				<div className={styles.imgBtnItem} onClick={() => setShowImgModal(true)}>
					<div className={`${styles.imgBtnContainer}`}>
						<div className={styles.body}>{t('lbl_img_and_subtitle_label')}</div>
						<div className={styles.imgBtn}>
							<IonLabel className={styles.imgBtnPlaceholder}>
								{customContentData.images.backgroundDesktopName
									? `${customContentData.images.backgroundDesktopName}, ${customContentData.images.backgroundMobileName}`
									: t('lbl_select')}
							</IonLabel>
							<Icon className={`icon24 icon-chevron-right`}></Icon>
						</div>
					</div>
				</div>
				<TextInput
					name="subtitleTranslations"
					value={customContentData.subtitleTranslations.find((t) => t.language === selectedLang)?.translation}
					label={t('lbl_img_and_subtitle_subtitleLabel')}
					className={styles.linkInput}
					placeholder={t('write')}
					onIonChange={(e) => {
						handleChangeCustomContent('subtitleTranslations', e.target.value);
					}}
					labelPlacement="start"
					maxlength={80}
					disabled={!hasWritePermission}
				/>
				<div className={`${styles.footnoteHint}`}>{t('lbl_custom_content_subtitle_hint')}</div>
			</div>
			{selectedTemplate === 'fullContent' ? (
				<>
					<div className={styles.element}>
						<h3 className={styles.blockTitle}>{t('lbl_service_content_title')}</h3>
						<p className={`${styles.blockSubtitle}`}>{t('lbl_service_content_desc')}</p>
						<ReactQuill
							id={'fullContent'}
							theme="snow"
							modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
							className={styles.quill}
							value={
								customContentData.fullContent.translationsContent.find((t) => t.language === selectedLang)
									?.translation
							} // TODO: Join in one property
							onChange={(e) => handleChangeCustomContent('fullContent', e)}
							// onFocus={() => {}}
							readOnly={!hasWritePermission}
							// preserveWhitespace={true}
						/>
					</div>
				</>
			) : (
				<>
					<div className={styles.element}>
						<h3 className={styles.blockTitle}>{t('lbl_links_title')}</h3>
						<p className={`${styles.blockSubtitle}`}>{t('lbl_links_desc')}</p>
						<div className={styles.linksBtnContainer}>
							{hasWritePermission && (
								<Button slot="start" className={styles.addLinkBtn} onClick={() => setShowLinkModal(true)}>
									<Icon className="icon icon-plus iconPlus" />
									<IonLabel>{t('btn_add_link')}</IonLabel>
								</Button>
							)}
							{hasWritePermission && customContentData.linkList.linkItems.length > 1 && (
								<div className={styles.reorderBtn}>
									<div className="buttons">
										<Toggle
											disabled={!hasWritePermission}
											checked={!edit}
											onChange={() => {
												setEdit((v) => !v);
											}}
										/>
									</div>
									<div className="label">
										<IonLabel>{t('reorder')}</IonLabel>
									</div>
								</div>
							)}
						</div>
						{edit ? (
							<>
								{customContentData.linkList.linkItems
									.filter((i) => i.action !== 'delete')
									.map((item) => {
										return (
											<IonItem
												key={`${
													item.translationsName.find((t) => t.language === selectedLang)?.translation
												}${item.id}`}
												className={styles.body}
											>
												<IonLabel>
													{item.translationsName.find((t) => t.language === selectedLang)?.translation}
												</IonLabel>
												{hasWritePermission && (
													<PopOverMenu
														id={item.id}
														actions={[
															{
																id: 0,
																text: t('lbl_modify'),
																action: () => {
																	setSelectedLink(item);
																	setShowLinkModal(true);
																},
																disabled: false,
																hide: false,
															},
															{
																id: 1,
																text: t('delete_text'),
																action: () => {
																	setDeleteId(item.id);
																},
																disabled: false,
																hide: customContentData.linkList.linkItems.length < 2,
															},
														]}
													/>
												)}
											</IonItem>
										);
									})}
							</>
						) : (
							<>
								<ReorderGroup
									className="orderGroup"
									disabled={!hasWritePermission}
									onIonItemReorder={handleGroupReorder}
								>
									{_.sortBy(customContentData.linkList.linkItems, 'order')
										.filter((item) => item.action !== 'delete')
										.map((item) => {
											return (
												<Reorder key={item.id}>
													<IonItem
														lines="full"
														key={item.id}
														button={true}
														onClick={() => {}}
														detail={false}
													>
														<IonLabel className={styles.body}>
															{
																item.translationsName.find((t) => t.language === selectedLang)
																	?.translation
															}
														</IonLabel>
														{hasWritePermission && <Icon className="icon icon-reordenar"></Icon>}
													</IonItem>
												</Reorder>
											);
										})}
								</ReorderGroup>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
};
