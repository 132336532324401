import { Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonContent, IonItem, IonLabel, IonList, IonPopover } from '@ionic/react';

type Props = {
	id: number;
	actions: { id: number; text: string; action: (id: number) => void; disabled?: boolean; hide?: boolean }[];
};

export const PopOverMenu: React.FC<Props> = ({ id, actions }) => {
	return (
		<>
			<IonButton id={'service-actions-' + id} className={`btnSelector`} fill="clear" slot="end">
				<Icon className="icon icon-ellipsis-vertical md" />
			</IonButton>
			<IonPopover trigger={'service-actions-' + id} dismissOnSelect={true} side="bottom" alignment="end">
				<IonContent>
					<IonList>
						{actions
							.filter((item) => !item.hide)
							.map((item) => {
								return (
									<IonItem
										lines="full"
										key={item.id}
										button={true}
										onClick={() => {
											item.action(id);
										}}
										detail={false}
										disabled={item.disabled}
									>
										<IonLabel>{item.text}</IonLabel>
									</IonItem>
								);
							})}
					</IonList>
				</IonContent>
			</IonPopover>
		</>
	);
};
