import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonLoading } from '@ionic/react';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import { termsServices } from '../../../_api/services/terms';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import useAppContext from '../../../hooks/useAppContext';
import { availableLanguages } from '../../../i18nextConf';
import styles from './styles.module.scss';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	footerStyle: string;
};

export const TermsGeneral: React.FC<Props> = (props) => {
	const { setError, setSuccess, hasWritePermission, footerStyle } = props;
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [data, setData] = useState<any>([]);
	const [formData, setFormData] = useState<any>([]);
	const [isEdited, setIsEdited] = useState(false);
	const [loadingSave, setLoadingSave] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const maxSize = parseInt(process.env.REACT_APP_MAX_FILE_TERMS_SIZE) * 1024 * 1024;
	const { isLoading: isLoading } = useQuery<string>(
		['terms_general'],
		async () => await termsServices.getGeneralTerms(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => setData(data),
		},
	);

	const onChange = (e, docType, lang) => {
		if (!_.isUndefined(e.target.files[0])) {
			if (e.target.files[0].type !== 'application/pdf') {
				setError(t('file_invalid'));
				return;
			}
			if (e.target.files[0].size > maxSize) {
				setError(t('file_size_invalid'));
				return;
			}
			const newFormData = new FormData(document.forms[0]);

			newFormData.set('docType', docType);
			newFormData.set('language', lang);
			newFormData.set('file', e.target.files[0]);

			const getNewFormData = (prevData) => {
				const nextData = prevData.map((document) => {
					if (document.docType !== docType || (document.docType === docType && document.language !== lang)) {
						return document;
					} else if (document.language === lang) {
						return { docType: docType, data: newFormData, filename: e.target.files[0].name, language: lang };
					}
				});

				const docTypeExistInLanguage = (language: string) => {
					return nextData.some((doc) => doc.docType === docType && doc.language === language);
				};

				availableLanguages.forEach((languageOption) => {
					if (!docTypeExistInLanguage(languageOption)) {
						nextData.push({
							docType: docType,
							data: newFormData,
							filename: e.target.files[0].name,
							language: languageOption,
						});
					}
				});

				return nextData;
			};

			setFormData((prev) => getNewFormData(prev));

			setIsEdited(true);
		}
	};

	const saveFiles = () => {
		setLoadingSave(true);
		Promise.allSettled(
			formData.map((data) => {
				return termsServices.saveGeneralTerms(data.data);
			}),
		)
			.then((res) => {
				if (res.every((r) => r.status === 'rejected')) {
					throw new Error(t('msg_upload_error'));
				} else if (res.some((r) => r.status === 'rejected')) {
					throw new Error(t('msg_partial_upload_error'));
				}
				setSuccess(t('msg_success'));
				setIsEdited(false);
				queryClient.refetchQueries('terms_general');
			})
			.catch((error) => {
				setError(error.message);
			})
			.finally(() => {
				setLoadingSave(false);
			});
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<p className={styles.footnote}>{t('text_links_policies')}</p>
			<p className={`${styles.footnote} ${styles.importantMessage}`}>
				<b>{t('important').toUpperCase()}</b>: {t('lbl_terms_important')}
			</p>
			<div className={styles.h2}>{t('generalPolicies')}</div>

			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('termsConditions')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb. ${t(
						'last_modify',
					)} : ${dayjs(
						_.first(
							data.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang),
						)?.lastDate?.replace('[UTC]', ''),
					).format('DD/MM/YYYY')}`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(
								formData.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang),
						  ) ? (
							_.first(data.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang))
								?.docName
						) : (
							_.first(formData.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang))
								?.filename
						)}
					</span>
					<input
						type="file"
						accept="application/pdf"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) => onChange(e, 'termsConditions', selectedLang)}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.docType === 'termsConditions' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('dataProtection')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb. ${t(
						'last_modify',
					)} : ${dayjs(
						_.first(
							data.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang),
						)?.lastDate?.replace('[UTC]', ''),
					).format('DD/MM/YYYY')}`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(
								formData.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang),
						  ) ? (
							_.first(data.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang))?.docName
						) : (
							_.first(formData.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang))
								?.filename
						)}
					</span>
					<input
						type="file"
						accept="application/pdf"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) => onChange(e, 'dataProtection', selectedLang)}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.docType === 'dataProtection' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			<div className={styles.containerInputs}>
				<div>
					<div className={styles.h3}>{t('cookiePolicy')}</div>
					<div className={`${styles.footnoteInput}`}>{`Max.${process.env.REACT_APP_MAX_FILE_TERMS_SIZE}Mb. ${t(
						'last_modify',
					)} : ${dayjs(
						_.first(
							data.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang),
						)?.lastDate?.replace('[UTC]', ''),
					).format('DD/MM/YYYY')}`}</div>
				</div>

				<label className={`${styles.input}`}>
					<span className={`${styles.h3} ${styles.color_dark}`}>
						{_.isEmpty(data.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang)) &&
						_.isEmpty(formData.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang)) ? (
							<span>
								<u>{t('lbl_select_one')}</u> {t('or_add_file')}
							</span>
						) : _.isEmpty(formData.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang)) ? (
							_.first(data.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang))?.docName
						) : (
							_.first(formData.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang))
								?.filename
						)}
					</span>
					<input
						type="file"
						accept="application/pdf"
						multiple={false}
						onClick={(e) => (e.currentTarget.value = '')}
						onChange={(e) => onChange(e, 'cookiePolicy', selectedLang)}
						disabled={!hasWritePermission}
					/>
					{_.isEmpty(data.filter((e) => e.docType === 'cookiePolicy' && e.language === selectedLang)) ? (
						<Icon className={`icon icon-arrow_down icon24 ${styles.iconRotate} ${styles.icon}`} />
					) : (
						<Icon className={`icon icon-close icon24  ${styles.icon}`} />
					)}
				</label>
			</div>
			{hasWritePermission && (
				<div className={`${footerStyle} ${styles.footerButton}`}>
					<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={() => saveFiles()}>
						{t('btn_save_data')}
					</Button>
				</div>
			)}
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={8000} />
		</>
	);
};
