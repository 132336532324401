import { useTranslation } from 'react-i18next';

import { IonItem } from '@ionic/react';
import { MenuItem } from '../../../_api/services/generalServices/types';

import { useState } from 'react';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { PopOverMenu } from '../../../components/PopOverMenu/PopOverMenu';
import { Spinner } from '../../../components/Spinner/Spinner';
import { useDelayedState } from '../../../hooks/useDelayedState';
import { ServiceIcon } from '../../IconsTranversal/components/ServiceIcon/ServiceIcon';
import styles from '../styles.module.scss';

type Props = {
	services: MenuItem[];
	lang: string;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	userHasPermissions: boolean;
	setSuccess: Function;
};

const ServicesTransversalList = ({
	services,
	handleEdit,
	handleDelete,
	lang,
	userHasPermissions,
	setSuccess,
}: Props) => {
	const { t } = useTranslation();
	const [showAlertDelService, setShowAlertDelService] = useState(false);
	const [selectedIdToDelete, setSelectedIdToDelete] = useState<number | null>(null);

	const showList = useDelayedState(false, true, 1500);

	const handleDeleteService = (id: number) => {
		setSelectedIdToDelete(id);
		setShowAlertDelService(true);
	};

	return (
		<>
			<Spinner show={!showList} />
			{services
				?.sort((a, b) => a.id - b.id)
				.map(({ id, translations, startIconSVG }) => {
					const { text } = translations?.find((t) => t.language === lang) || {};
					return (
						<IonItem
							key={id}
							className={styles.serviceInputRow}
							style={{ visibility: showList ? 'visible' : 'hidden' }}
						>
							<ServiceIcon url={startIconSVG?.url} ariaLabel={text} />
							<span className={styles.serviceName}>{text}</span>
							{userHasPermissions && (
								<PopOverMenu
									id={id}
									actions={[
										{
											id: 0,
											text: t('lbl_modify'),
											action: (id) => handleEdit(id),
											disabled: false,
										},
										{
											id: 1,
											text: t('delete_text'),
											action: (id) => handleDeleteService(id),
											disabled: false,
										},
									]}
								/>
							)}
						</IonItem>
					);
				})}
			<CustomModal
				showModal={showAlertDelService}
				onConfirm={() => {
					handleDelete(selectedIdToDelete);
					setShowAlertDelService(false);
					setSuccess(t('msg_success_delete_service'));
				}}
				onClose={() => {
					setShowAlertDelService(false);
					setSelectedIdToDelete(null);
				}}
				title={t('service_delete_modal.title')}
				description={t('service_delete_modal.description')}
			></CustomModal>
		</>
	);
};

export default ServicesTransversalList;
