import { useEffect, useState } from 'react';

export const useDelayedState = (initialValue, finalValue, delay) => {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		const timeout = setTimeout(() => setValue(finalValue), delay);
		return () => {
			clearTimeout(timeout);
		};
	}, []);

	return value;
};
