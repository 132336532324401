import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import {
	InputChangeEventDetail,
	InputCustomEvent,
	IonButton,
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { TextInput } from '../../../../components/TextInput/TextInput';

import { useTranslation } from 'react-i18next';
import { BuildingData } from '../../../../_api/services/workstation/types';
import { ISpace } from '../types';

import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

interface IProps {
	showFloorModal: boolean;
	discardModal: () => void;
	acceptModal: () => void;
	selected: {
		building: ISpace | undefined;
		floor: ISpace | undefined;
	};
	selectedLang: string;
	editBuildingData: BuildingData[];
	editLegendAbbreviation: (id: number, value: string) => void;
	editLegendDescription: (id: number, value: string) => void;
	removeLegend: (id: number) => void;
	addNewLegend: () => void;
	hasWritePermission: boolean;
	dismissModal: () => void;
}

const LegendsModal = ({
	showFloorModal,
	discardModal,
	acceptModal,
	selected,
	selectedLang,
	editBuildingData,
	editLegendAbbreviation,
	editLegendDescription,
	removeLegend,
	addNewLegend,
	dismissModal,
	hasWritePermission,
}: IProps) => {
	const { t } = useTranslation();
	const [canSave, setCanSave] = useState(false);

	useEffect(() => {
		if (!selected?.building?.id || !selected?.floor?.id) {
			return;
		}
		const floor = editBuildingData
			.find((b) => b.id === selected.building.id)
			?.floors.find((f) => f.id === selected.floor.id);
		if (!floor) return;
		const isEdited = !!JSON.stringify(floor).match(/"action":/g);
		const allFilled = floor.legends
			.filter((l) => l.action !== 'delete')
			.every((l) => l.abbreviation !== '' && l.translations.every((t) => t.translation !== ''));
		setCanSave(isEdited && allFilled && hasWritePermission);
	}, [selected?.building?.id, selected?.floor?.id, editBuildingData, hasWritePermission]);

	return (
		<Modal isOpen={showFloorModal} onDidDismiss={dismissModal}>
			<IonToolbar>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>
							{t(`tlt_edit_floor_legends`, {
								building: selected?.building?.name,
								floor: selected?.floor?.name,
								lang: t('language_' + selectedLang),
							})}
						</b>
					</h3>
				</IonTitle>
				<Button slot="end" fill={'clear'} color="light" className={styles.closeModalBtn} onClick={discardModal}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-close"></Icon>
					</IonLabel>
				</Button>
			</IonToolbar>
			<IonContent>
				<div className={`${styles.footnote} ${styles.space}`}>{t('modal_add_legend_subtitle')}</div>
				{editBuildingData
					.find((b) => {
						return b.id === selected?.building?.id;
					})
					?.floors?.find((f) => f.id === selected?.floor?.id)
					.legends.filter((l) => l.action !== 'delete')
					.map((l) => {
						return (
							<IonItem key={l.id} lines="none" className={`${styles.legend} ${false && styles.touchedInput}`}>
								<TextInput
									label={t('lbl_legend_abbr')}
									placeholder={t('lbl_write')}
									name="legend_abbr"
									className={`ion-text-end`}
									disabled={!hasWritePermission}
									onIonChange={(e: InputCustomEvent<InputChangeEventDetail>) => {
										editLegendAbbreviation(l.id, e.target.value as string);
									}}
									value={l.abbreviation}
								/>
								<TextInput
									label={t('lbl_legend_description')}
									placeholder={t('lbl_write')}
									name="legend_descr"
									className={`ion-text-end`}
									disabled={!hasWritePermission}
									onIonChange={(e: InputCustomEvent<InputChangeEventDetail>) => {
										editLegendDescription(l.id, e.target.value as string);
									}}
									value={l.translations?.find((t) => t.language === selectedLang)?.translation}
								/>
								<IonButton
									fill="clear"
									onClick={() => {
										removeLegend(l.id);
									}}
									className={styles.buttonDelete}
									slot="end"
									disabled={!hasWritePermission}
								>
									<Icon className={`icon icon-delete icon24`} />
								</IonButton>
							</IonItem>
						);
					})}
				<Button className={styles.addLegendBtn} onClick={addNewLegend} disabled={!hasWritePermission}>
					<Icon className="icon icon-plus iconPlus" />
					<IonLabel>{t('btn_add_legend')}</IonLabel>
				</Button>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={discardModal}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button color="primary" className={styles.btnHeader} disabled={!canSave} onClick={acceptModal}>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default LegendsModal;
