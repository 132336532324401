import useAppContext from '../../../hooks/useAppContext';
import packageJson from '../../../../package.json';
import { CONFIGURATION_PREFERENCES } from '../../../utils/constants';
import styles from '../styles.module.scss';

export const HeaderMenu = () => {
	const { sedePortal, configurationPreference } = useAppContext();
	const sedeDescription = sedePortal?.description ?? '';
	const headerText =
		configurationPreference === CONFIGURATION_PREFERENCES.transversal.id
			? configurationPreference.toUpperCase()
			: sedeDescription.toUpperCase();

	return (
		<div className={styles.headerMenu}>
			<div className={styles.iconAndVersion}>
				<img src="/campus_acciona.svg" alt="Logo Acciona" />
				<h2 className={styles.appVersion}>{packageJson?.version ?? ''}</h2>
			</div>
			<h2 className={styles.sedeHeader}>{headerText}</h2>
		</div>
	);
};
