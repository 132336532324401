import muleSoftInstance from '../../utils/httpCommon';
import { IIcon, MenuConfig, MenuItem, ServiceConfig, ServiceCustomContentConfig } from './types';

const getServiceDetailedConfig = async (service: number): Promise<ServiceConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/servicesConfig?serviceId=${service}`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data[0];
	} catch (err) {
		throw new Error(err);
	}
};
const getServiceCustomContentConfig = async (service: number): Promise<ServiceCustomContentConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/servicesConfig?serviceId=${service}`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data[0];
	} catch (err) {
		throw new Error(err);
	}
};
const saveServiceDetailedConfig = async (data: ServiceConfig): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/servicesConfig`;
	try {
		const resp = await muleSoftInstance.put(path, [data]);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getShuttles = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/shuttles`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveShuttles = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/shuttles`;
	try {
		const resp = await muleSoftInstance.post(path, form);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getRestaurants = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/restaurants`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveRestaurants = async (form): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/restaurants`;
	try {
		const resp = await muleSoftInstance.post(path, form);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getServicesConfig = async (): Promise<MenuConfig> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/categoriesAndServices`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveServicesConfig = async (data: MenuConfig) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/categoriesAndServices`;
	try {
		const resp = await muleSoftInstance.put(path, data);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const saveUniqueServiceConfig = async (data: MenuItem) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/serviceConfiguration`;
	try {
		const resp = await muleSoftInstance.put(path, data);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const saveUniqueServiceCustomConfig = async (id: number, data: ServiceCustomContentConfig[]) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v2/servicesConfig?serviceId=${id}`;
	try {
		const resp = await muleSoftInstance.put(path, data);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const getTransversalServices = async (): Promise<MenuItem[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/othersServices`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveTransversalServices = async (data: MenuItem[]) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/othersServices`;
	try {
		const resp = await muleSoftInstance.put(path, data);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const uploadFile = async (formData: FormData) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/services/fileConfiguration`;
	try {
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const resp = await muleSoftInstance.post(path, formData, config);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const uploadBackgroundImage = async (formData: FormData) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/backGroundImage`;
	try {
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const resp = await muleSoftInstance.post(path, formData, config);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getTransversalIcons = async (): Promise<IIcon[]> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/icons`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateTranversalIconsName = async (data: IIcon[]) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/icons`;
	try {
		const resp = await muleSoftInstance.put(path, data);
		return resp;
	} catch (err) {
		throw new Error(err);
	}
};

const uploadIcon = async (formData: FormData) => {
	const path = `${process.env.REACT_APP_API_PORTAL}/icon`;
	try {
		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};
		const resp = await muleSoftInstance.post(path, formData, config);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const generalServicesService = {
	getShuttles,
	saveShuttles,
	getRestaurants,
	saveRestaurants,
	getServiceDetailedConfig,
	saveServiceDetailedConfig,
	getServicesConfig,
	saveServicesConfig,
	saveUniqueServiceConfig,
	getTransversalServices,
	saveTransversalServices,
	uploadFile,
	uploadBackgroundImage,
	getTransversalIcons,
	updateTranversalIconsName,
	uploadIcon,
	getServiceCustomContentConfig,
	saveUniqueServiceCustomConfig,
};
