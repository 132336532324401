import { Button, Icon, SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import { IonContent, IonItem, IonLabel, IonRadio, IonRadioGroup, IonTitle, IonToolbar } from '@ionic/react';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ConfigType,
	LinkItem,
	MenuItem,
	MenuItemTranslation,
	ServiceCustomContentConfig,
} from '../../../../_api/services/generalServices/types';

import PageModal from '../../../../components/PageModal/PageModal';
import styles from './styles.module.scss';

import { generalServicesService } from '../../../../_api/services/generalServices';

import { LanguageSelector } from '../../../../components/LanguageSelector/LanguageSelector';
import {
	checkChanges,
	checkValidServiceConfig,
	checkValidServiceConfigAllLanguages,
	FILE_SIZE,
	getSelectedConfig,
	serviceConfigOptions,
	ServiceEndIcons,
	TemplateType,
	unconfigCustomContentService,
} from '../helpers';
import ExternalAccess from './ExternalAccess';
import { ModalHeaderPage } from './ModalHeaderPage/ModalHeaderPage';

import _ from 'lodash';
import { useQuery, useQueryClient } from 'react-query';
import { arraymove } from '../../../../utils/functions';

type Props = {
	service: MenuItem;
	isOpen: boolean;
	handleClose: () => void;
	setError: Function;
	setSuccess: Function;
	setThereAreUnsavedChanges?: Function;
	hasWritePermission: boolean;
};

enum ESegments {
	select = 'SELECT',
	configure = 'CONFIGURE',
}

const SettingsEditModal: React.FC<Props> = ({
	service,
	isOpen,
	handleClose,
	setError,
	setSuccess,
	setThereAreUnsavedChanges,
	hasWritePermission,
}) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [lang, setLang] = useState('es');
	const [serviceMod, setServiceMod] = useState(structuredClone(service));
	const [canSave, setCanSave] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [segmentedSelected, setSegmentedSelected] = useState(ESegments.select);
	const [customContentData, setCustomContentData] = useState<ServiceCustomContentConfig>(unconfigCustomContentService);
	const [initialData, setInitialData] = useState<ServiceCustomContentConfig>({
		...unconfigCustomContentService,
		id: service.serviceId,
	});
	const [configureAvailable, setConfigureAvailable] = useState(false);
	const serviceTitle = service?.translations.find((t) => t.language === lang)?.text;

	useQuery<ServiceCustomContentConfig>(
		['customContent'],
		async () => await generalServicesService.getServiceCustomContentConfig(service.serviceId),
		{
			refetchOnWindowFocus: false,
			onError: (error: Error) => {
				setError(error.message);
				setCustomContentData({ ...unconfigCustomContentService, id: service.serviceId });
			},
			onSuccess: (data) => {
				setConfigureAvailable(true);
				if (!_.isEmpty(data)) {
					setInitialData(data);
					setCustomContentData(data);
				} else {
					setCustomContentData({ ...unconfigCustomContentService, id: service.serviceId });
				}
			},
		},
	);

	const handleChangeServiceConfig = (config: ConfigType) => {
		setServiceMod((oldState) => {
			const newState = structuredClone(oldState);
			newState.translations.forEach((t) => {
				t.externalAppLink.active = false;
				t.externalLink.active = false;
				t.file.active = false;
				t.internalLink.active = false;
				t[config].active = true;
			});

			if ([ConfigType.EXTERNAL_APP_LINK, ConfigType.EXTERNAL_LINK].includes(config)) {
				newState.endIcon = ServiceEndIcons.EXTERN;
			} else if ([ConfigType.INTERNAL_LINK].includes(config)) {
				newState.endIcon = ServiceEndIcons.INTERN;
			} else if ([ConfigType.FILE].includes(config)) {
				newState.endIcon = ServiceEndIcons.FILE;
			}

			return newState;
		});
	};

	const generateFormData = (data: Record<string, any>) => {
		const formData = new FormData();
		for (const key in data) {
			formData.set(key, data[key]);
		}
		return formData;
	};

	const handleChangeCustomContent = (field: any, value: any) => {
		if (field === 'images') {
			setCustomContentData((oldState) => {
				const newState = structuredClone(oldState);
				newState.images = {
					...newState.images,
					...value,
				};

				return newState;
			});
		}
		if (field === 'subtitleTranslations') {
			setCustomContentData((oldState) => {
				const newState = structuredClone(oldState);
				const isAllEmtpy = newState.subtitleTranslations.every((t) => {
					return !t.translation || t.translation === '';
				});
				newState.subtitleTranslations.forEach((t) => {
					if (isAllEmtpy || t.language === lang) {
						t.translation = value;
					}
				});
				return newState;
			});
		}
		if (field === 'fullContent') {
			setCustomContentData((oldState) => {
				const newState = structuredClone(oldState);
				const isAllEmtpy = newState.fullContent.translationsContent.every((t) => {
					return !t.translation || t.translation === '';
				});
				newState.fullContent.translationsContent.forEach((t) => {
					if (isAllEmtpy || t.language === lang) {
						t.translation = value;
					}
				});
				return newState;
			});
		}
		if (field === 'template') {
			setCustomContentData((oldState) => {
				const newState = structuredClone(oldState);
				for (const key in TemplateType) {
					newState[TemplateType[key]].active = false;
				}
				newState[value].active = true;
				return newState;
			});
		}
		if (field === 'link') {
			setCustomContentData((oldState) => {
				const newState = structuredClone(oldState);
				const modLink = newState.linkList.linkItems.find((link) => link.id === value.id);
				if (!modLink) {
					value.action = 'create';
					value.order = newState.linkList.linkItems.length + 1;
					value.id = new Date().getTime();
					newState.linkList.linkItems.push(structuredClone(value));
				} else {
					modLink.translationsContent = value.translationsContent;
					modLink.translationsName = value.translationsName;
					modLink.action = modLink.action === 'create' ? 'create' : 'update';
				}
				return newState;
			});
		}
	};

	const handleChangeServiceValue = async (key: string, value: any, selectedConfig: ConfigType) => {
		if (key === 'fileName') {
			const form = value.target;
			if (form.files.length === 0) {
				return;
			}
			if (form.files[0].size > FILE_SIZE['10MB']) {
				setError(t('file_size_invalid'));
				return;
			}
		}

		setServiceMod((oldState) => {
			const newState = structuredClone(oldState);

			const isAllEmtpy = newState.translations.every((t) => {
				return !t[selectedConfig][key] || t[selectedConfig][key] === '';
			});

			if (key === 'fileName') {
				const fileName = value.target.value.split('\\').at(-1);
				const file = value.target.files[0];
				newState.translations.forEach((t) => {
					if (isAllEmtpy || t.language === lang) {
						t.file.fileName = fileName;
						t.file.file = file;
					}
				});
			} else {
				newState.translations.forEach((t) => {
					if (
						isAllEmtpy ||
						t.language === lang ||
						selectedConfig === ConfigType.INTERNAL_LINK ||
						(selectedConfig === ConfigType.EXTERNAL_LINK && key === 'inAppBrowser')
					) {
						t[selectedConfig][key] = value;
					}
				});
			}

			return newState;
		});
	};

	const handleSaveFiles = async () => {
		const serviceWithFiles = structuredClone(serviceMod);
		for (const tr of serviceWithFiles.translations) {
			if (tr.file.file) {
				const formData = generateFormData({
					fileName: tr.file.fileName,
					file: tr.file.file,
					language: tr.language,
					id: service.id?.toString(),
				});
				const url = await generalServicesService.uploadFile(formData);
				tr.file.url = url;
				delete tr.file.file;
			}
		}
		return serviceWithFiles;
	};

	const handleSaveImages = async () => {
		const serviceWithImages = structuredClone(customContentData);
		if (serviceWithImages.images.backgroundDesktopData) {
			const url = await generalServicesService.uploadBackgroundImage(
				generateFormData({
					file: serviceWithImages.images.backgroundDesktopData,
					id: service.serviceId?.toString(),
					type: 'desktop',
				}),
			);
			serviceWithImages.images.backgroundDesktop = url;
			delete serviceWithImages.images.backgroundDesktopData;
		}
		if (serviceWithImages.images.backgroundMobileData) {
			const url = await generalServicesService.uploadBackgroundImage(
				generateFormData({
					file: serviceWithImages.images.backgroundMobileData,
					id: service.serviceId?.toString(),
					type: 'mobile',
				}),
			);
			serviceWithImages.images.backgroundMobile = url;
			delete serviceWithImages.images.backgroundMobileData;
		}
		return serviceWithImages;
	};

	const handleReorderItemList = (e) => {
		setCustomContentData((oldState) => {
			const newState = structuredClone(oldState);
			const action = e.detail as any;
			newState.linkList.linkItems = arraymove(
				_.sortBy(newState.linkList.linkItems, 'order'),
				action.from,
				action.to,
			).map((e: LinkItem, index) => {
				return {
					...e,
					order: index + 1,
					action: e.action && e.action !== 'update' ? e.action : 'update',
				};
			});
			return newState;
		});
	};

	const handleDeleteItem = (id: number) => {
		setCustomContentData((oldState) => {
			const newState = structuredClone(oldState);
			const item = newState.linkList.linkItems.find((i) => i.id === id);
			if (!item) return oldState;

			if (item.action === 'create') {
				newState.linkList.linkItems = newState.linkList.linkItems.filter((i) => i.id !== id);
			} else {
				item.action = 'delete';
			}
			newState.linkList.linkItems
				.filter((i) => i.action !== 'delete')
				.forEach((linkitem, index) => {
					linkitem.order = index + 1;
					linkitem.action = linkitem.action === 'create' ? 'create' : 'update';
				});
			setSuccess(t('lbl_custom_content_link_modal_delete'));
			return newState;
		});
	};

	const handleSave = async (isCustomContentSelected: boolean = false) => {
		setIsSaving(true);
		try {
			const finalServiceConfig = await handleSaveFiles();
			const finalCustomContentConfig = await handleSaveImages();
			await generalServicesService.saveUniqueServiceConfig(finalServiceConfig);
			if (isCustomContentSelected) {
				await generalServicesService.saveUniqueServiceCustomConfig(service.serviceId, [
					{
						...finalCustomContentConfig,
						firstConfig: !!finalCustomContentConfig.firstConfig,
					},
				]);
				await queryClient.refetchQueries('customContent');
			}
			setSuccess(t('msg_success'));
			await queryClient.refetchQueries('serviceSettings');
		} catch (err) {
			setError(err?.message);
		} finally {
			setIsSaving(false);
		}
	};

	const resetConfig = () => {
		setCustomContentData(initialData);
		setServiceMod(service);
	};

	const checkServiceConfigChanges = (
		serviceConfig: MenuItemTranslation,
		customContentDataMod: ServiceCustomContentConfig,
		configType: ConfigType,
	): boolean => {
		if (configType === ConfigType.INTERNAL_LINK) return _.isEqual(customContentDataMod, initialData);
		return _.isEqual(serviceConfig[configType], service.translations.find((t) => t.language === lang)[configType]);
	};

	useEffect(() => {
		if (!service) return;
		setServiceMod(structuredClone(service));
	}, [service]);

	useEffect(() => {
		const isServiceEqual = checkChanges(serviceMod, service);
		const isContentEqual = _.isEqual(customContentData, initialData);
		const isValid = checkValidServiceConfig(serviceMod);
		setThereAreUnsavedChanges(!isServiceEqual || !isContentEqual);
		setCanSave(!isServiceEqual && isValid);
	}, [customContentData, initialData, service, serviceMod]);

	useEffect(() => {
		resetConfig();
	}, [segmentedSelected]);

	return (
		<PageModal isOpen={isOpen} handleClose={handleClose}>
			<IonToolbar className={styles.serviceModalToolbar}>
				<Button slot="start" fill={'clear'} color="light" className={styles.closeModalBtn} onClick={handleClose}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-chevron-left"></Icon>
					</IonLabel>
				</Button>
				<IonTitle className={`centerTitle ${styles.title}`}>
					<h3>
						<b>{t(`lbl_configure_service`, { name: serviceTitle })}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<div className={`${styles.tabsLineSeparator} ${styles.noMarginLeft}`} />
			<ModalHeaderPage>
				<Segmented value={segmentedSelected}>
					<SegmentButton
						onClick={() => setSegmentedSelected(ESegments.select)}
						value={ESegments.select}
						mode={'md'}
					>
						<IonLabel>{t('tab_services_settings_select')}</IonLabel>
					</SegmentButton>
					<SegmentButton
						onClick={() => setSegmentedSelected(ESegments.configure)}
						value={ESegments.configure}
						mode={'md'}
						disabled={!configureAvailable}
					>
						<IonLabel>{t('tab_services_settings_configure')}</IonLabel>
					</SegmentButton>
				</Segmented>
			</ModalHeaderPage>
			<IonContent className={styles.serviceSettingsModalBody}>
				<div className={styles.container}>
					{segmentedSelected === ESegments.select && (
						<>
							<IonItem lines="none">{t('lbl_select_service_link', { name: serviceTitle })}</IonItem>
							<IonRadioGroup
								value={getSelectedConfig(serviceMod, lang)}
								onIonChange={(e) => handleChangeServiceConfig(e.detail.value)}
								className={styles.configSelectorRadio}
							>
								{serviceConfigOptions?.map((item) => {
									return (
										<IonRadio
											key={item.value}
											disabled={
												!checkValidServiceConfigAllLanguages(
													serviceMod,
													customContentData,
													item.value,
													hasWritePermission,
												)
											}
											value={item.value}
											labelPlacement="end"
										>
											{t(item.name)}
										</IonRadio>
									);
								})}
							</IonRadioGroup>
						</>
					)}

					{segmentedSelected === ESegments.configure && (
						<div>
							<LanguageSelector selectedLang={lang} setSelectedLang={setLang} />
							<ExternalAccess
								serviceTitle={serviceTitle}
								serviceConfig={serviceMod?.translations.find((t) => t.language === lang)}
								resetConfig={resetConfig}
								handleSave={handleSave}
								checkServiceConfigChanges={checkServiceConfigChanges}
								selectedConfig={getSelectedConfig(serviceMod, lang)}
								handleChangeServiceConfig={handleChangeServiceConfig}
								handleChangeServiceValue={handleChangeServiceValue}
								handleChangeCustomContent={handleChangeCustomContent}
								hasWritePermission={hasWritePermission}
								selectedLang={lang}
								serviceId={service.id}
								setSuccess={setSuccess}
								setError={setError}
								customContentData={customContentData}
								handleReorderItemList={handleReorderItemList}
								handleDeleteItem={handleDeleteItem}
								isSaving={isSaving}
							/>
						</div>
					)}
				</div>
			</IonContent>
			{segmentedSelected === ESegments.select && hasWritePermission && (
				<div className={`${styles.hasScroll} ${styles.footerButton}`}>
					<Button
						disabled={!canSave || isSaving}
						onClick={() => handleSave()}
						className={styles.btnHeader}
						color="primary"
					>
						{t('btn_post')}
					</Button>
				</div>
			)}
		</PageModal>
	);
};

export default SettingsEditModal;
