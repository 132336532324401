import { Button, List } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { IonLabel, IonLoading } from '@ionic/react';
import ReactQuill from 'react-quill';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../utils/constants';
import { NotificationsProps, DeskNotificationsConfig } from './types';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAppContext from '../../../hooks/useAppContext';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { Toggle } from '../../../components/Toggle/Toggle';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import { workstationService } from '../../../_api/services/workstation';
import { defaultDeskNotificationsConfig } from '../../../_api/services/workstation/mockData';

export const DeskNotifications: React.FC<NotificationsProps> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;
	const [notificationsConfig, setNotificationsConfig] =
		useState<DeskNotificationsConfig>(defaultDeskNotificationsConfig);
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [changeQuill, setChangeQuill] = useState(false);
	useEffect(() => {
		setThereAreUnsavedChanges(isEdited && hasWritePermission);
	}, [isEdited, hasWritePermission]);

	useEffect(() => {
		setIsEdited(!_.isEqual(notificationsConfig, initialValues));
	}, [notificationsConfig]);

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['deskNotificationsConfig'],
		async () => workstationService.getDeskNotificationConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setNotificationsConfig(data);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(workstationService.updateDeskNotificationConfig, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('deskNotificationsConfig');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const checkinBody = useMemo(() => {
		return notificationsConfig.checkinTranslations.find((translate) => translate.language === selectedLang)?.body;
	}, [notificationsConfig.checkinTranslations, selectedLang]);

	const isTranslationInvalid = (translation: string): boolean => {
		return _.isEmpty(translation) || translation === '<p><br></p>';
	};

	// Handlers
	const handleInputChange = (e) => {
		const fieldName = e.target.name;
		const value = String(e.target.value); //Sometimes value is number if click with mouse instead of keyboard
		setNotificationsConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: value,
			};
		});
	};

	const handleToggleChange = (fieldName: string) => {
		setNotificationsConfig((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return {
				...nextValues,
				[fieldName]: !nextValues[fieldName],
			};
		});
	};

	const handleCheckinEmailTextChange = (e) => {
		if (changeQuill) {
			const newTranslation = notificationsConfig.checkinTranslations.map((translation) =>
				translation.language === selectedLang ? { ...translation, body: e } : translation,
			);
			const newConfig = { ...notificationsConfig, checkinTranslations: newTranslation };
			setNotificationsConfig(newConfig);
		}
	};
	const completeAllTranslations = (config: DeskNotificationsConfig): DeskNotificationsConfig => {
		const isAnyBodyEmpty = config.checkinTranslations.some(
			(translation) => translation.body.trim() === '' || translation.body === '<p><br></p>',
		);
		const checkinTranslationForAll = config.checkinTranslations.find((t) => t.language === selectedLang)?.body;
		if (isAnyBodyEmpty && !_.isEmpty(checkinTranslationForAll)) {
			const newCheckinTranslation = config.checkinTranslations.map((translation) =>
				translation.body === '' || translation.body === '<p><br></p>'
					? { ...translation, body: checkinTranslationForAll }
					: translation,
			);
			const newConfig = {
				...notificationsConfig,
				checkinTranslations: newCheckinTranslation,
			};
			return newConfig;
		}
		return config;
	};
	const handleSaveConfig = () => {
		handleSave(completeAllTranslations(notificationsConfig));
		setIsEdited(false);
	};
	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={3000} />
			) : (
				<>
					<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
					<List>
						{/* CHECK-IN */}
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_pk_notifications_checkin')}
						</header>
						{/* DESCRIPTION */}
						<div className={`${styles.element} ${styles.toggles}`}>
							<IonLabel className={`${styles.footnote}`}>{t('lbl_desk_notifications_desc')}</IonLabel>
						</div>
						{/* CHECKIN TOGGLES */}
						<div className={styles.element}>
							<div className={`${styles.h3} ${styles.space}`}>{t('lbl_enable_notifications')}</div>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_desk_checkin_toggles_description')}
							</div>
							<div className={styles.checkinToggles}>
								<div className={styles.toggleItem}>
									<Toggle
										checked={notificationsConfig.permanentReservations}
										onChange={() => handleToggleChange('permanentReservations')}
										disabled={!hasWritePermission}
									/>
									<IonLabel className={`${styles.footnote} ${styles.space}`}>
										{t('lbl_desk_notifications_toggles.permanent')}
									</IonLabel>
								</div>
								<div className={styles.toggleItem}>
									<Toggle
										checked={notificationsConfig.reservations}
										onChange={() => handleToggleChange('reservations')}
										disabled={!hasWritePermission}
									/>
									<IonLabel className={`${styles.footnote} ${styles.space}`}>
										{t('lbl_desk_notifications_toggles.reservations')}
									</IonLabel>
								</div>
							</div>
						</div>
						{/* CHECKIN NOTIFICATIONS BEFORE SEND MAIL*/}
						<div className={styles.element}>
							<div className={`${styles.h3} ${styles.space}`}>{t('lbl_checkin_notifications_title')}</div>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_desk_notifications_checkinBeforeMail_description')}
							</div>
							<NumberInput
								name="notificationsBeforeMail"
								className={`ion-text-end`}
								value={+notificationsConfig.notificationsBeforeMail}
								onIonChange={handleInputChange}
								min={0}
								disabled={!hasWritePermission}
								aria-label={t('lbl_pk_notifications_number_notifications')}
								label={t('lbl_pk_notifications_number_notifications')}
								required
							></NumberInput>
						</div>
						{/* CHECKIN EMAIL BODY */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_checkinMailBody')}
							</div>
							<ReactQuill
								id="pkCheckinMailNotificationBody"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.quill}
								value={checkinBody}
								onChange={handleCheckinEmailTextChange}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
								preserveWhitespace={true}
							/>
						</div>
						{/* CHECKIN DAYS BEFORE RESET COUNT*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_checkin_daysBeforeReset')}
							</div>
							<NumberInput
								name="daysBeforeReset"
								className={`ion-text-end`}
								value={+notificationsConfig.daysBeforeReset}
								onIonChange={handleInputChange}
								min={0}
								disabled={!hasWritePermission}
								aria-label={t('lbl_number_of_days')}
								label={t('lbl_number_of_days')}
								required
								allowDecimals={false}
							></NumberInput>
						</div>
					</List>
					{/* -------- FOOTER -------------------------------------------------------- */}
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button
								onClick={handleSaveConfig}
								className={styles.btnHeader}
								disabled={
									!isEdited ||
									isTranslationInvalid(
										notificationsConfig.checkinTranslations.find(
											(translate) => translate.language === selectedLang,
										)?.body,
									)
								}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
		</>
	);
};
