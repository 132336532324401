import { Button, Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonContent, IonItem, IonLabel, IonList, IonLoading, IonPopover } from '@ionic/react';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { generalServicesService } from '../../_api/services/generalServices';
import { IIcon } from '../../_api/services/generalServices/types';
import { Feedback } from '../../components/Feedback/Feedback';
import LayoutNoTabs from '../../components/LayoutNoTabs/LayoutNoTabs';
import { Spinner } from '../../components/Spinner/Spinner';
import useAppContext from '../../hooks/useAppContext';
import { useDelayedState } from '../../hooks/useDelayedState';
import { usePermissions } from '../../hooks/usePermissions';
import { FUNCTIONALITIES } from '../../utils/constants';
import { AddIconModal } from './components/AddIconModal/AddIconModal';
import { EditIconModal } from './components/EditIconModal/EditIconModal';
import { ServiceIcon } from './components/ServiceIcon/ServiceIcon';
import { popoverOptions } from './helpers';
import styles from './styles.module.scss';
import CustomModal from '../../components/CustomModal/CustomModal';

export const IconsTransversal: React.FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [icons, setIcons] = useState<IIcon[]>([]);
	const [selectedIcon, setSelectedIcon] = useState<IIcon>();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [isEdited, setIsEdited] = useState(false);
	const { permissions: userHasPermissions } = usePermissions(FUNCTIONALITIES.superadmin, 'WRITE');
	const [showEditIconModal, setShowEditIconModal] = useState(false);
	const [showAddIconModal, setShowAddIconModal] = useState(false);
	const [showDeleteIconAlert, setShowDeleteIconAlert] = useState(false);
	const [showIconInUseAlert, setShowIconInUseAlert] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [info, setInfo] = useState(null);
	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	const { data: initialValues, isLoading: isLoading } = useQuery(
		['getIcons'],
		generalServicesService.getTransversalIcons,
		{
			onSuccess: (data: any) => {
				setIcons(data);
			},
			refetchOnWindowFocus: false,
			onError: (error: Error) => {
				setError(error.message);
			},
		},
	);

	const { mutate, isLoading: loadingSave } = useMutation(
		async (icons: IIcon[]) => await generalServicesService.updateTranversalIconsName(icons),
		{
			onSuccess: () => {
				queryClient.refetchQueries('getIcons');
				setSuccess(t('msg_success'));
			},
			onError: (err: Error) => {
				setError(err.message);
				setIsEdited(true);
			},
		},
	);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	useEffect(() => {
		setIsEdited(!_.isEqual(icons, initialValues));
	}, [icons]);

	const handleOptions = (icon: IIcon, orderId: number) => {
		setSelectedIcon(icon);
		if (orderId === 2) {
			if (icon.inUse) {
				setShowIconInUseAlert(true);
			} else {
				setShowDeleteIconAlert(true);
			}
			return;
		}
		setShowEditIconModal(true);
	};

	const handleUpdateIconName = (icon: IIcon) => {
		setIcons((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return nextValues.map((i) => {
				return i.id === icon.id ? { ...i, name: icon.name } : i;
			});
		});
	};

	const handleDeleteIcon = (icon: IIcon) => {
		setIcons((prevValues) => {
			const nextValues = _.cloneDeep(prevValues);
			return nextValues.map((i) => {
				return i.id === icon.id ? { ...i, delete: true } : i;
			});
		});
	};

	const handleAddNewIcon = (icon: IIcon) => {
		const newIcons = [...icons, icon];
		setIcons(newIcons);
	};

	const uploadAllIcons = async (icons: IIcon[]): Promise<any> => {
		return Promise.allSettled(
			icons
				.filter((icon) => icon.svgContent && !icon.delete)
				.map((icon): Promise<any> => {
					const formData = new FormData();
					const svgBlob = new Blob([icon.svgContent], { type: 'image/svg+xml' });
					const svgFile = new File([svgBlob], icon.svgName, { type: 'image/svg+xml' });
					formData.set('file', svgFile);
					formData.set('name', icon.name);
					return new Promise((resolve) => {
						(async () => {
							try {
								await generalServicesService.uploadIcon(formData);
							} catch (err) {
								setError(err?.message);
							}
							resolve(null);
						})();
					});
				}),
		);
	};

	const handleSave = async () => {
		setIsEdited(false);
		await uploadAllIcons(icons);
		const iconsChanged = icons.filter((icon) => {
			const initialIcon = initialValues.find((i) => i.id === icon.id);
			return initialIcon && (icon.name !== initialIcon.name || icon.delete !== initialIcon.delete);
		});
		if (iconsChanged.length > 0) {
			mutate(iconsChanged);
		} else {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('getIcons');
		}
	};

	const showList = useDelayedState(false, true, 1500);

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} />
			) : (
				<LayoutNoTabs title={t('menu_icons')}>
					{userHasPermissions.WRITE && (
						<>
							<h3 className={styles.blockTitle}>{t('lbl_add_icons')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('lbl_add_new_icon')}</p>
							<Button
								slot="start"
								className={styles.editableListBtnAdd}
								onClick={() => {
									setShowAddIconModal(true);
								}}
							>
								<Icon className="icon icon-plus iconPlus" />
								<IonLabel>{t('btn_add_icon')}</IonLabel>
							</Button>
						</>
					)}
					<Spinner show={!showList} />
					{icons
						.filter((icon) => !icon.delete)
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((icon) => {
							return (
								<IonItem
									key={`${icon.name}${icon.id}`}
									className={styles.iconRow}
									style={{ visibility: showList ? 'visible' : 'hidden' }}
								>
									{icon.svgName ? (
										<div className={styles.svgIcon} dangerouslySetInnerHTML={{ __html: icon.svgContent }} />
									) : (
										<ServiceIcon url={icon.url} ariaLabel={icon.name}></ServiceIcon>
									)}
									<IonLabel>{icon.name}</IonLabel>
									<IonButton
										className={`btnSelector ${styles.parkingPopoverButton}`}
										disabled={!userHasPermissions}
										fill="clear"
										id={`icon-${icon.id}`}
										slot="end"
									>
										<Icon className="icon icon-ellipsis-vertical md" />
									</IonButton>
									<IonPopover trigger={`icon-${icon.id}`} dismissOnSelect={true} side="bottom" alignment="end">
										<IonContent>
											<IonList className={`${styles.listPadding}`}>
												{popoverOptions.map((item) => {
													return (
														<IonItem
															lines="full"
															key={item.id}
															button={true}
															onClick={() => handleOptions(icon, item.id)}
															detail={false}
														>
															<IonLabel className={styles.body}>{item.text}</IonLabel>
														</IonItem>
													);
												})}
											</IonList>
										</IonContent>
									</IonPopover>
								</IonItem>
							);
						})}

					{userHasPermissions.WRITE && (
						<div className={`${styles.footerButton}`}>
							<Button
								onClick={handleSave}
								className={styles.btnHeader}
								disabled={!isEdited || loadingSave}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
					<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
					{selectedIcon && (
						<EditIconModal
							showModal={showEditIconModal}
							onClose={() => {
								setShowEditIconModal(false);
								setSelectedIcon(null);
							}}
							selectedIcon={selectedIcon}
							saveChanges={handleUpdateIconName}
							setSuccess={setSuccess}
						/>
					)}
					<AddIconModal
						showModal={showAddIconModal}
						onClose={() => {
							setShowAddIconModal(false);
						}}
						saveChanges={handleAddNewIcon}
						hasWritePermission={userHasPermissions.WRITE}
						setSuccess={setSuccess}
						setError={setError}
					/>
					<CustomModal
						showModal={showDeleteIconAlert}
						onConfirm={() => {
							handleDeleteIcon(selectedIcon);
							setSelectedIcon(null);
							setShowDeleteIconAlert(false);
							setSuccess(t('msg_success_delete_icon'));
						}}
						onClose={() => {
							setShowDeleteIconAlert(false);
							setSelectedIcon(null);
						}}
						title={t('alert_delete_icon.title')}
						description={t('alert_delete_icon.description')}
					></CustomModal>
					<CustomModal
						showModal={showIconInUseAlert}
						onConfirm={() => {
							setShowIconInUseAlert(false);
							setSelectedIcon(null);
						}}
						onClose={() => {
							setShowIconInUseAlert(false);
							setSelectedIcon(null);
						}}
						title={t('alert_icon_in_use.title')}
						description={t('alert_icon_in_use.description')}
						onlyAcceptButton
					></CustomModal>
				</LayoutNoTabs>
			)}
		</>
	);
};
