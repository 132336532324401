import { IonSpinner } from '@ionic/react';
import styles from './styles.module.scss';

type Props = {
	show: boolean;
};

export const Spinner: React.FC<Props> = ({ show }) => {
	return (
		<>
			{show && (
				<div className={styles.spinner}>
					<IonSpinner />
				</div>
			)}
		</>
	);
};
