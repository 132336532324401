import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { LinkItem } from '../../../../../_api/services/generalServices/types';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../../../utils/constants';
import styles from './styles.module.scss';
import _ from 'lodash';

type Props = {
	showModal: boolean;
	onClose: () => void;
	setSuccess?: Function;
	setError?: any;
	hasWritePermission: boolean;
	linkData: LinkItem;
	selectedLang: string;
	handleChangeCustomContent: (field: string, value: any) => void;
};
export const EditCustomContentLink: React.FC<Props> = ({
	showModal,
	onClose,
	setSuccess,
	hasWritePermission,
	linkData,
	selectedLang,
	handleChangeCustomContent,
}) => {
	const { t } = useTranslation();
	const [canSave, setCanSave] = useState(false);
	const [linkMod, setLinkMod] = useState(structuredClone(linkData));
	const [changeQuill, setChangeQuill] = useState(false);

	useEffect(() => {
		const updateCanSave = (linkMod: LinkItem) => {
			setCanSave(
				linkMod.translationsName.find((t) => t.language === selectedLang)?.translation !== '' &&
					linkMod.translationsContent.find((t) => t.language === selectedLang)?.translation !== '' &&
					!_.isEqual(linkMod, linkData),
			);
		};
		updateCanSave(linkMod);
	}, [linkMod]);

	const handleInputChange = (key: string, value: string): void => {
		setLinkMod((oldState) => {
			const link = structuredClone(oldState);
			link[key].find((t) => t.language === selectedLang).translation = value;
			return link;
		});
	};

	const handleSave = () => {
		const link = structuredClone(linkMod);
		const newTranslateContent = link.translationsContent.find((t) => t.language === selectedLang)?.translation;
		const newTranslateName = link.translationsName.find((t) => t.language === selectedLang)?.translation;
		link.translationsContent.forEach((t) => {
			if (t.translation === '') {
				t.translation = newTranslateContent;
			}
		});
		link.translationsName.forEach((t) => {
			if (t.translation === '') {
				t.translation = newTranslateName;
			}
		});
		setSuccess(
			t(link.order > 0 ? 'lbl_custom_content_link_modal_modify_link' : 'lbl_custom_content_link_modal_add_link'),
		);
		handleChangeCustomContent('link', link);
		onClose();
	};

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				onClose();
				setLinkMod(structuredClone(linkData));
				setChangeQuill(false);
			}}
			className={styles.modalWithOpacity}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={onClose}>
					<IonLabel className="dark">
						<Icon color="primary" className="icon icon-close icon20"></Icon>
					</IonLabel>
				</Button>
				<IonTitle>
					<h3 className={`ion-text-center ${styles.headline}`}>
						<b>{`${t('lbl_custom_content_link_modal_title')}`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={styles.element}>
					<p className={`${styles.blockSubtitle}`}>{t('lbl_custom_content_link_modal_name')}</p>
					<TextInput
						name="translationsName"
						value={linkMod.translationsName.find((t) => t.language === selectedLang)?.translation}
						label={t('name')}
						className={styles.linkInput}
						placeholder={t('write')}
						onIonChange={(e) => {
							handleInputChange(e.target.name, e.target.value as string);
						}}
						labelPlacement="start"
						maxlength={80}
						disabled={!hasWritePermission}
					/>
				</div>
				<div className={styles.element}>
					<p className={`${styles.blockSubtitle}`}>{t('lbl_custom_content_link_modal_content')}</p>
					<ReactQuill
						id={'fullContent'}
						theme="snow"
						modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
						className={styles.quill}
						value={linkMod.translationsContent.find((t) => t.language === selectedLang)?.translation} // TODO: Join in one property
						onChange={(e) => changeQuill && handleInputChange('translationsContent', e)}
						readOnly={!hasWritePermission}
						onFocus={() => setChangeQuill(true)}
						// preserveWhitespace={true}
					/>
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={!canSave || !hasWritePermission}
						onClick={handleSave}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
