import { IIcon, MenuItem } from '../../_api/services/generalServices/types';
import _ from 'lodash';

export const availableServiceIcons: {
	value: string;
	label: string;
}[] = [
	{
		value: 'icon icon-globe',
		label: 'Globe',
	},
	{
		value: 'icon icon-plate',
		label: 'Plate',
	},
	{
		value: 'icon icon-gym',
		label: 'Gym',
	},
	{
		value: 'icon icon-doctor',
		label: 'Doctor',
	},
	{
		value: 'icon icon-canal_etico',
		label: 'Canal Ético',
	},
	{
		value: 'icon icon-acciona_hoy',
		label: 'Acciona Today',
	},
	{
		value: 'icon icon-recaderia',
		label: 'Recadería',
	},
	{
		value: 'icon icon-biblioteca',
		label: 'Biblioteca',
	},
	{
		value: 'icon icon-campus',
		label: 'Campus',
	},
];

export const checkServicesValid = (services: MenuItem[], lang: string) => {
	return services.every((service) => {
		if (service.created) {
			const translation = service.translations.find((t) => t.language === lang)?.text;
			return translation && translation.trim() !== '';
		}
		return service.translations.every((translation) => {
			return translation.text.trim() !== '';
		});
	});
};

export const generateNewService = (name: string, icon: IIcon) => ({
	id: Date.now(),
	order: 0,
	section: [],
	permission: '',
	endIcon: '',
	active: false,
	startIcon: availableServiceIcons[0].value,
	startIconSVG: icon,
	translations: [
		{
			language: 'es',
			text: name,
		},
		{
			language: 'en',
			text: name,
		},
		{
			language: 'pt',
			text: name,
		},
	],
	created: true,
});

export const setModificationFlag = (item: MenuItem, originalData: MenuItem[]) => {
	if (item.deleted || item.created) return item;
	const prevItem = originalData.find((i) => i.id === item.id);
	if (!_.isEqual(item, prevItem)) {
		item.modified = true;
	}

	return item;
};

export const fillEmptyTranslations = (service: MenuItem, lang: string): MenuItem => {
	const fillUpText = service.translations.find((t) => {
		return t.language === lang;
	})?.text;

	if (!fillUpText || fillUpText === '') {
		return service;
	}

	service.translations.forEach((t) => {
		if (t.text === '') {
			t.text = fillUpText;
		}
	});

	return service;
};

export const createServicePermission = (service: MenuItem): MenuItem => {
	service.permission = service.translations
		.find((t) => t.language === 'es')
		?.text.toLowerCase()
		.replace(/ /g, '_')
		.replace(/[^a-z0-9_]/g, '');
	return service;
};
