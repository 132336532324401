import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonImg, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IIcon, MenuItem } from '../../../_api/services/generalServices/types';
import { Spinner } from '../../../components/Spinner/Spinner';
import { TextInput } from '../../../components/TextInput/TextInput';
import { useDelayedState } from '../../../hooks/useDelayedState';
import styles from '../styles.module.scss';

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: ({ id, name, icon }: { id?: number; name: string; icon: IIcon }) => void;
	iconList: IIcon[];
	selectedService?: MenuItem;
	lang?: string;
}

export const ModalCreateEditService: React.FC<IProps> = ({
	isOpen,
	onClose,
	onConfirm,
	iconList,
	selectedService,
	lang,
}: IProps) => {
	const { t } = useTranslation();
	const [serviceData, setServiceData] = useState<{
		name: string;
		icon?: IIcon;
	}>({
		name: selectedService?.translations?.find((t) => t.language === lang)?.text || '',
		icon: selectedService?.startIconSVG,
	});

	const [isEdited, setIsEdited] = useState(false);

	const showIcons = useDelayedState(false, true, 1500);

	const handleInput = (name: string, value: any) => {
		setServiceData((oldState) => {
			return { ...oldState, [name]: typeof value === 'string' ? value.trim() : value };
		});
	};

	const handleConfirm = () => {
		onConfirm({ id: selectedService?.id, name: serviceData.name, icon: serviceData.icon });
		onClose();
	};

	useEffect(() => {
		const nameChanged =
			serviceData.name !== selectedService?.translations?.find((t) => t.language === lang)?.text || '';
		const iconChanged = serviceData?.icon?.id !== selectedService?.startIconSVG?.id;
		setIsEdited(nameChanged || iconChanged);
	}, [serviceData, selectedService]);

	return (
		<Modal
			isOpen={isOpen}
			onDidDismiss={() => {
				onClose();
			}}
			className={`${styles.modal}`}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className={styles.closeModalBtn} onClick={() => onClose()}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-close"></Icon>
					</IonLabel>
				</Button>
				<IonTitle className="centerTitle">
					<h3>
						<b>{t(selectedService ? 'lbl_edit_service' : 'lbl_create_service')}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<p className={styles.footnote}>{t('edit_service_modal_desc')}</p>
				<TextInput
					value={serviceData.name}
					name={'name'}
					label={t('lbl_service_name')}
					className={`${styles.serviceNameInput} ion-text-end`}
					onIonChange={(e) => handleInput('name', e.target.value as string)}
					maxlength={32}
				/>
				<Spinner show={!showIcons} />
				<div className={styles.iconList} style={{ visibility: showIcons ? 'visible' : 'hidden' }}>
					{iconList.map(({ id, url, name }) => {
						return (
							<div
								key={id}
								onClick={() => {
									handleInput('icon', { id, url, name });
								}}
								className={`${styles.icon} ${id === serviceData.icon?.id ? styles.selected : ''}`}
							>
								<span className={styles.tooltip}>{name}</span>
								<IonImg key={id} src={url} />
							</div>
						);
					})}
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						color="light"
						className={styles.btnHeader}
						onClick={() => {
							onClose();
						}}
					>
						{t('cancel_text')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						disabled={serviceData?.name?.trim().length === 0 || !serviceData?.icon || !isEdited}
						className={styles.btnHeader}
						onClick={handleConfirm}
					>
						{t(selectedService ? 'btn_save_data' : 'btn_create_service')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default ModalCreateEditService;
