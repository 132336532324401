import styles from './styles.module.scss';

type Props = {
	children?: React.ReactNode;
	className?: string;
	separator?: boolean;
};

export const ModalHeaderPage: React.FC<Props> = ({ children, className, separator = true }) => {
	return (
		<div className={`${styles.menuSegmentedModal} ${className ? className : ''}`}>
			<div className={styles.tabsMenuModal}>{children}</div>
			{separator && <div className={styles.tabsLineSeparatorModal} />}
		</div>
	);
};
