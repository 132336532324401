import { PkNotificationsConfig } from './types';

export const defaultNotificationsConfig: PkNotificationsConfig = {
	sendNotificationEnabled: true,
	sendEmailEnabled: false,
	minutesBeforeEndNotification: '15',
	minutesBeforeEndEmail: '30',
	doNotSendAfter: '22:00',
	daysBeforeReset: '4',
	emailTranslations: [
		{ language: 'es', subject: '', body: 'Ejemplo' },
		{ language: 'en', subject: '', body: '' },
		{ language: 'pt', subject: '', body: '' },
	],
	reservations: true,
	permanentReservations: true,
	notificationsBeforeMail: '6',
	checkinTranslations: [
		{
			body: 'body (en html)',
			language: 'es',
		},
		{ language: 'en', subject: '', body: '' },
		{ language: 'pt', subject: '', body: '' },
	],
};

export const MINUTES_OPTIONS = ['0', '5', '10', '15', '20', '25', '30'];
