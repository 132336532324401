import { Button, FilterChip } from '@acciona/ui-ionic-kit';
import { IonItem, IonLabel } from '@ionic/react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import {
	ConfigType,
	MenuItemTranslation,
	ServiceCustomContentConfig,
} from '../../../../_api/services/generalServices/types';
import { FileInput } from '../../../../components/FileInput/FileInput';
import { TextInput } from '../../../../components/TextInput/TextInput';
import { Toggle } from '../../../../components/Toggle/Toggle';
import { checkValidServiceConfigTranslation, serviceConfigOptions } from '../helpers';
import { CustomContent } from './CustomContent';
import styles from './styles.module.scss';

type Props = {
	serviceTitle?: string;
	serviceConfig: MenuItemTranslation;
	resetConfig: () => void;
	handleSave: (isCustomContentSelected: boolean) => void;
	checkServiceConfigChanges: (
		serviceConfig: MenuItemTranslation,
		customContent: ServiceCustomContentConfig,
		configType: ConfigType,
	) => boolean;
	selectedConfig: ConfigType;
	handleChangeServiceConfig?: (config: ConfigType) => void;
	handleChangeServiceValue: (key: string, value: any, selectedConfig: ConfigType) => void;
	handleChangeCustomContent: (field: string, value: any) => void;
	hasWritePermission: boolean;
	selectedLang: string;
	serviceId: number;
	setError: Function;
	setSuccess: Function;
	customContentData: ServiceCustomContentConfig;
	handleReorderItemList: (e: any) => void;
	handleDeleteItem: (id: number) => void;
	isSaving: boolean;
};

const ExternalAccess: React.FC<Props> = ({
	selectedConfig,
	handleChangeServiceValue,
	handleChangeCustomContent,
	serviceConfig,
	resetConfig,
	handleSave,
	checkServiceConfigChanges,
	hasWritePermission,
	selectedLang,
	customContentData,
	setSuccess,
	setError,
	handleReorderItemList,
	handleDeleteItem,
	isSaving,
}) => {
	const { t } = useTranslation();
	const [selectedConfigType, setSelectedConfigType] = useState<ConfigType>(selectedConfig ?? ConfigType.EXTERNAL_LINK);

	const inputConfig: Record<
		ConfigType,
		{
			type: 'text' | 'toggle' | 'file';
			name: string;
			value: string | boolean;
			label: string;
		}[]
	> = {
		[ConfigType.FILE]: [
			{
				type: 'file',
				name: 'fileName',
				value: serviceConfig?.file?.fileName,
				label: t('file'),
			},
		],
		[ConfigType.EXTERNAL_LINK]: [
			{
				type: 'text',
				name: 'url',
				value: serviceConfig?.externalLink?.url,
				label: t('link'),
			},
			{
				type: 'toggle',
				name: 'inAppBrowser',
				value: serviceConfig?.externalLink?.inAppBrowser,
				label: t('lbl_hide_url_bar'),
			},
		],
		[ConfigType.EXTERNAL_APP_LINK]: [
			{
				type: 'text',
				name: 'deepLinkAndroid',
				value: serviceConfig?.externalAppLink?.deepLinkAndroid,
				label: t('lbl_deep_link_android'),
			},
			{
				type: 'text',
				name: 'deepLinkIOS',
				value: serviceConfig?.externalAppLink?.deepLinkIOS,
				label: t('lbl_deep_link_ios'),
			},
			{
				type: 'text',
				name: 'downloadAppAndroid',
				value: serviceConfig?.externalAppLink?.downloadAppAndroid,
				label: t('lbl_download_link_android'),
			},
			{
				type: 'text',
				name: 'downloadAppIOS',
				value: serviceConfig?.externalAppLink?.downloadAppIOS,
				label: t('lbl_download_link_ios'),
			},
			{
				type: 'text',
				name: 'webUrl',
				value: serviceConfig?.externalAppLink?.webUrl,
				label: t('lbl_app_link_web'),
			},
		],
		[ConfigType.INTERNAL_LINK]: [],
	};

	if (!serviceConfig) {
		return;
	}

	const canSave = useMemo(() => {
		const isValid = checkValidServiceConfigTranslation(serviceConfig, customContentData, selectedConfigType);
		const isEqual = checkServiceConfigChanges(serviceConfig, customContentData, selectedConfigType);
		return isValid && !isEqual;
	}, [serviceConfig, customContentData, selectedConfigType]);

	return (
		<div>
			{/* TIPO CONFIGURACION */}
			<div className={styles.element}>
				<h3 className={styles.blockTitle}>{t('lbl_configuration_type_title')}</h3>
				<p className={`${styles.blockSubtitle}`}>{t('lbl__configuration_type_desc')}</p>
				{/* CHIPS */}
				<div className={styles.chipContainer}>
					{serviceConfigOptions.map((config) => {
						return (
							<FilterChip
								key={`filter-chip-${config.value}`}
								id={`${config.value}`}
								text={t(config.name)}
								checked={selectedConfigType === config.value}
								onChange={() => {
									resetConfig();
									setSelectedConfigType(config.value);
								}}
							/>
						);
					})}
				</div>
			</div>
			{selectedConfigType === ConfigType.INTERNAL_LINK ? (
				<CustomContent
					customContentData={customContentData}
					hasWritePermission={hasWritePermission}
					selectedLang={selectedLang}
					handleChangeCustomContent={handleChangeCustomContent}
					setSuccess={setSuccess}
					setError={setError}
					handleReorderItemList={handleReorderItemList}
					handleDeleteItem={handleDeleteItem}
				/>
			) : (
				<>
					{inputConfig[selectedConfigType]?.map(({ type, name, value, label }) => {
						switch (type) {
							case 'file':
								return (
									<FileInput
										key={name}
										fileName={value as string}
										disabled={!hasWritePermission}
										onChange={(e) => {
											handleChangeServiceValue(name, e as any, selectedConfigType);
										}}
										accept={'.pdf,.html,.htm'}
									/>
								);
							case 'toggle':
								return (
									<IonItem lines="none" key={name}>
										<IonLabel>{label}</IonLabel>
										<Toggle
											checked={value as boolean}
											onChange={() => {
												handleChangeServiceValue(name, !value, selectedConfigType);
											}}
											disabled={!hasWritePermission}
										/>
									</IonItem>
								);
							case 'text':
								return (
									<TextInput
										key={name}
										name={name}
										value={value as string}
										label={label}
										className={styles.linkInput}
										placeholder={t('lbl_write_url')}
										onIonChange={(e) => {
											handleChangeServiceValue(name, e.target.value, selectedConfigType);
										}}
										labelPlacement="start"
										disabled={!hasWritePermission}
									/>
								);
							default:
								return <></>;
						}
					})}
				</>
			)}
			{hasWritePermission && (
				<div className={`${styles.hasScroll} ${styles.footerButton}`}>
					<Button
						disabled={!canSave || isSaving}
						onClick={() => handleSave(selectedConfigType === ConfigType.INTERNAL_LINK)}
						className={styles.btnHeader}
						color="primary"
					>
						{t('btn_post')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default ExternalAccess;
