import _ from 'lodash';
import {
	ConfigType,
	LinkItem,
	MenuItem,
	MenuItemTranslation,
	ServiceCustomContentConfig,
} from '../../../_api/services/generalServices/types';

export enum FILE_SIZE {
	'1MB' = 1e6,
	'5MB' = 5e6,
	'10MB' = 1e7,
}

export const serviceConfigOptions = [
	{
		value: ConfigType.EXTERNAL_LINK,
		name: 'lbl_external_link',
	},
	{
		value: ConfigType.FILE,
		name: 'lbl_file',
	},
	{
		value: ConfigType.EXTERNAL_APP_LINK,
		name: 'lbl_app_link',
	},
	{
		value: ConfigType.INTERNAL_LINK,
		name: 'lbl_internal_link',
	},
];

export enum ServiceEndIcons {
	EXTERN = 'icon icon-extern',
	INTERN = 'icon icon-chevron-right',
	FILE = 'icon icon-news',
}

export const getSelectedConfig = (service: MenuItem, lang: string): ConfigType => {
	const translation = service?.translations.find((t) => t.language === lang);
	if (!translation) return ConfigType.EXTERNAL_LINK;
	if (translation.externalLink?.active) {
		return ConfigType.EXTERNAL_LINK;
	}
	if (translation.file?.active) {
		return ConfigType.FILE;
	}
	if (translation.externalAppLink?.active) {
		return ConfigType.EXTERNAL_APP_LINK;
	}
	if (translation.internalLink?.active) {
		return ConfigType.INTERNAL_LINK;
	}
	return undefined;
};

export const checkChanges = (mod: MenuItem, original: MenuItem) => {
	return _.isEqual(original, mod);
};

export const checkValidServiceConfig = (service: MenuItem): boolean => {
	const textInputsAreCompleted = service?.translations.every((t) => {
		if (t.externalAppLink.active) {
			return (
				t.externalAppLink.deepLinkAndroid !== '' &&
				t.externalAppLink.deepLinkIOS !== '' &&
				t.externalAppLink.downloadAppAndroid !== '' &&
				t.externalAppLink.downloadAppIOS !== '' &&
				t.externalAppLink.webUrl !== ''
			);
		}
		if (t.externalLink.active) {
			return t.externalLink.url !== '';
		}
		if (t.internalLink.active) {
			return true;
		}
		if (t.file.active) {
			return t.file.fileName && t.file.fileName !== '';
		}
	});
	return textInputsAreCompleted;
};

export const checkValidServiceConfigTranslation = (
	service: MenuItemTranslation,
	customContent: ServiceCustomContentConfig,
	type: ConfigType,
): boolean => {
	if (type === ConfigType.EXTERNAL_APP_LINK) {
		return (
			service.externalAppLink.deepLinkAndroid !== '' &&
			service.externalAppLink.deepLinkIOS !== '' &&
			service.externalAppLink.downloadAppAndroid !== '' &&
			service.externalAppLink.downloadAppIOS !== '' &&
			service.externalAppLink.webUrl !== ''
		);
	}
	if (type === ConfigType.EXTERNAL_LINK) {
		return service.externalLink.url !== '';
	}
	if (type === ConfigType.INTERNAL_LINK) {
		return (
			customContent?.fullContent.active ||
			(customContent?.linkList.active && customContent?.linkList.linkItems.length !== 0)
		);
	}
	if (type === ConfigType.FILE) {
		return service.file.fileName && service.file.fileName !== '';
	}
	// AÑADIR CONFIGTYPE CUSTOM_CONTENT
	return true;
	// Utilizar en futura HU para deshablitar del IonRadioGroup las opciones no configuradas -> disabled={!checkValidServiceConfigForEachType(serviceMod, item.value)}
};

export const checkValidServiceConfigAllLanguages = (
	service: MenuItem,
	customContent: ServiceCustomContentConfig,
	type: ConfigType,
	hasWritePermission: boolean,
): boolean => {
	if (!hasWritePermission) return false;
	if (type === ConfigType.EXTERNAL_APP_LINK) {
		return service.translations.every((t) => {
			return (
				t.externalAppLink.deepLinkAndroid !== '' &&
				t.externalAppLink.deepLinkIOS !== '' &&
				t.externalAppLink.downloadAppAndroid !== '' &&
				t.externalAppLink.downloadAppIOS !== '' &&
				t.externalAppLink.webUrl !== ''
			);
		});
	}
	if (type === ConfigType.EXTERNAL_LINK) {
		return service.translations.every((t) => {
			return t.externalLink.url !== '';
		});
	}
	if (type === ConfigType.INTERNAL_LINK) {
		return (
			customContent?.fullContent.active ||
			(customContent?.linkList.active && customContent?.linkList.linkItems.length !== 0)
		);
	}
	if (type === ConfigType.FILE) {
		return service.translations.every((t) => {
			return t.file.fileName && t.file.fileName !== '';
		});
	}

	return true;
};

export enum TemplateType {
	LINKS = 'linkList',
	FULLCONTENT = 'fullContent',
}

export const templateOptions = [
	{ id: 0, value: TemplateType.FULLCONTENT, configurationName: 'lbl_template_fullContent' },
	{ id: 1, value: TemplateType.LINKS, configurationName: 'lbl_template_links' },
];
export const checkSelectedTemplate = (data: ServiceCustomContentConfig): TemplateType => {
	if (data.fullContent?.active) return TemplateType.FULLCONTENT;
	return TemplateType.LINKS;
};
export const unconfigCustomContentService: ServiceCustomContentConfig = {
	id: -1,
	firstConfig: true,
	titleTranslations: [
		{
			language: 'es',
			translation: '',
		},
		{
			language: 'en',
			translation: '',
		},
		{
			language: 'pt',
			translation: '',
		},
	],
	subtitleTranslations: [
		{
			language: 'es',
			translation: '',
		},
		{
			language: 'en',
			translation: '',
		},
		{
			language: 'pt',
			translation: '',
		},
	],
	images: { backgroundMobile: '', backgroundMobileName: '', backgroundDesktop: '', backgroundDesktopName: '' },
	linkList: { active: true, linkItems: [] },
	fullContent: {
		active: false,
		translationsContent: [
			{
				language: 'es',
				translation: '',
			},
			{
				language: 'en',
				translation: '',
			},
			{
				language: 'pt',
				translation: '',
			},
		],
	},
};

export const newLinkItem: LinkItem = {
	id: 1e9,
	enabled: true,
	external: false,
	order: -1,
	translationsName: [
		{
			language: 'es',
			translation: '',
		},
		{
			language: 'en',
			translation: '',
		},
		{
			language: 'pt',
			translation: '',
		},
	],
	translationsContent: [
		{
			language: 'es',
			translation: '',
		},
		{
			language: 'en',
			translation: '',
		},
		{
			language: 'pt',
			translation: '',
		},
	],
};
