import { BuildingAndFloors, ReservationsConfig } from '../../../_api/services/workstation/types';

export const checkIfBuildingAndFloorExist = (data: ReservationsConfig, buildings: BuildingAndFloors[]): boolean => {
	const defaultBuilding = buildings.find((b) => b.building === data.defaultVisitsBuilding);
	if (defaultBuilding) {
		if (defaultBuilding.floors.find((f) => f.floor === data.defaultVisitsFloor)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
};
