import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonTitle, IonToolbar } from '@ionic/react';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageDetails } from '../../../../../_api/services/generalServices/types';
import { FileInput } from '../../../../../components/FileInput/FileInput';
import styles from './styles.module.scss';

type Props = {
	showModal: boolean;
	onClose: () => void;
	setSuccess?: any;
	setError?: any;
	hasWritePermission: boolean;
	fileData: ImageDetails;
	setFileData: (data: FileData) => void;
};

type FileData = Omit<ImageDetails, 'backgroundDesktop' | 'backgroundMobile'>;

export const EditCustomContentImgModal: React.FC<Props> = ({
	showModal,
	onClose,
	hasWritePermission,
	fileData,
	setFileData,
	setError,
}) => {
	const { t } = useTranslation();
	const [canSave, setCanSave] = useState(false);
	const [modFileData, setModFileData] = useState<FileData>({
		backgroundDesktopName: fileData.backgroundDesktopName,
		backgroundDesktopData: undefined,
		backgroundMobileName: fileData.backgroundMobileName,
		backgroundMobileData: undefined,
	});

	const handleFileChange = (event, type: string) => {
		const file = event.target.files[0];
		if (!file || !file.type.includes('image/')) {
			setError(t('lbl_custom_content_img_modal_type_error'));
			return;
		}
		const img = new Image();
		const objectURL = URL.createObjectURL(file);
		img.onload = function () {
			const width = img.width;
			const height = img.height;
			if (type === 'desktop' && (width > 2000 || height > 1400)) {
				setError(t('img_desktop_resolution_error'));
				return;
			}
			if (type === 'mobile' && (width > 340 || height > 800)) {
				setError(t('img_smartphone_resolution_error'));
				return;
			}
			setModFileData((oldState) => {
				const newState = structuredClone(oldState);
				if (type === 'desktop') {
					newState.backgroundDesktopName = file.name;
					newState.backgroundDesktopData = file;
				} else if (type === 'mobile') {
					newState.backgroundMobileName = file.name;
					newState.backgroundMobileData = file;
				}
				return newState;
			});
		};
		img.src = objectURL;
	};

	const handleAccept = () => {
		setFileData(modFileData);
		onClose();
	};

	useEffect(() => {
		const updateCanSave = (fileData: FileData) => {
			setCanSave(!!fileData.backgroundDesktopName && !!fileData.backgroundMobileName);
		};
		updateCanSave(modFileData);
	}, [modFileData]);

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				onClose();
				setModFileData(fileData);
			}}
			className={styles.modalWithOpacity}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className="btnCloseModal" onClick={onClose}>
					<IonLabel className="dark">
						<Icon color="primary" className="icon icon-close icon20"></Icon>
					</IonLabel>
				</Button>
				<IonTitle>
					<h3 className={`ion-text-center ${styles.headline}`}>
						<b>{`${t('lbl_custom_content_img_modal_title')}`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<p className={styles.footnote}>{t('lbl_custom_content_img_modal_desc')}</p>
				<div className={styles.inputsContainer}>
					<FileInput
						title={t('img_desktop')}
						subtitle={t('max_file_values')}
						fileName={modFileData.backgroundDesktopName}
						disabled={!hasWritePermission}
						onChange={(e) => handleFileChange(e, 'desktop')}
						inputOptions={{ accept: 'image/*' }}
					/>
					<FileInput
						title={t('img_smartphone')}
						subtitle={t('max_img_smartphone')}
						fileName={modFileData.backgroundMobileName}
						disabled={!hasWritePermission}
						onChange={(e) => handleFileChange(e, 'mobile')}
						inputOptions={{ accept: 'image/*' }}
					/>
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={!canSave || !hasWritePermission}
						onClick={handleAccept}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
